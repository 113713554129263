/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): contactform.js
 * --------------------------------------------------------------------------
 */

import {
  defineJQueryPlugin
} from '../../bootstrap/js/src/util/index'
import {
  createElement,
  inView,
  append
} from './util/index'
import Data from '../../bootstrap/js/src/dom/data'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

const NAME = 'contactform'
const DATA_KEY = 'onma.contactform'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  type: 'POST',
  dataType: 'json',
  url: 'mailer.php',
  recaptcha: true
}

const DefaultType = {
  type: 'string',
  dataType: 'string',
  url: 'string',
  recaptcha: 'boolean'
}

const Selector = {
  BODY: 'body',
  CONTACTFORM: '[data-bs-form="contact"]',
  ERROR_MESSAGE_CONTAINER: '.error-message-container',
  SUCCESS_MESSAGE_CONTAINER: '.success-message-container'
}

const Event = {
  SCROLL: `scroll${EVENT_KEY}`,
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  SUBMIT_DATA_API: `submit${EVENT_KEY}${DATA_API_KEY}`
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class ContactForm extends CommonComponent {
  constructor(element, config) {
    super(element, config)

    this._body = SelectorEngine.findOne(Selector.BODY)

    this._errorMessageContainer = SelectorEngine.findOne(Selector.ERROR_MESSAGE_CONTAINER, this._element)
    this._successMessageContainer = SelectorEngine.findOne(Selector.SUCCESS_MESSAGE_CONTAINER, this._element)

    this._hideStatusMessageContainers()

    EventHandler.on(this._element, Event.SUBMIT_DATA_API, event => {
      event.preventDefault()
      this._submitForm()
      return false
    })

    if (this._config.recaptcha) {
      if (inView(this._element)) {
        this._initializeRecaptcha()
      } else {
        EventHandler.on(window, Event.SCROLL, () => {
          if (inView(this._element)) {
            this._initializeRecaptcha()
          }
        })
      }
    }
  }

  // getters
  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private
  _initializeRecaptcha() {
    const recaptchaScriptTag = createElement('script')
    recaptchaScriptTag.setAttribute('type', 'text/javascript')
    recaptchaScriptTag.setAttribute('src', 'https://www.google.com/recaptcha/api.js?hl=de')
    append(this._body, recaptchaScriptTag)
  }

  _submitForm() {
    /* eslint-disable no-undef */
    const form = this._element
    const formData = new FormData(form)

    if (typeof grecaptcha !== 'undefined' && grecaptcha) {
      formData.append('recaptcha', grecaptcha.getResponse())
    }

    this._hideStatusMessageContainers()

    const request = new XMLHttpRequest()
    request.addEventListener('error', () => {
      this._showErrorMessage()
    })
    request.addEventListener('load', () => {
      if (request.status === 200) {
        const data = JSON.parse(request.response)
        if (data.status) {
          form.reset()
          if (typeof grecaptcha !== 'undefined' && grecaptcha) {
            grecaptcha.reset()
          }

          this._successMessageContainer.innerHTML = data.message
          this._successMessageContainer.style.display = 'block'
          if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
              event: 'contact_form_success'
            })
          }
        } else if (data.validation) {
          this._errorMessageContainer.innserHTML = data.message
          this._errorMessageContainer.style.display = 'block'
          for (const key of Object.entries(data.errors)) {
            SelectorEngine.findOne(`[name="${key}"]`, form).classList.add('is-invalid')
          }

          if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
              event: 'contact_form_error'
            })
          }
        } else {
          this._errorMessageContainer.innserHTML = data.message
          this._errorMessageContainer.style.display = 'block'
          if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
              event: 'contact_form_error'
            })
          }
        }
      } else {
        this._showErrorMessage()
      }
    })
    request.open(this._config.type, this._config.url, true)
    request.send(formData)
    /*
    $.ajax({
      type: this._config.type,
      url: this._config.url,
      data: formData,
      dataType: this._config.dataType,
      processData: false,
      contentType: false,
      error: () => {
        this.errorMessageContainer.html('Fehler beim Senden der Anfrage. Bitte versuchen Sie es später nochmal.').show();
      },
      success: (data) => {
        if (data.status) {
          form.reset();
          if (typeof grecaptcha !== 'undefined' && grecaptcha) {
            grecaptcha.reset()
          }
          this.successMessageContainer.html(data.message).show()
          if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
              event: 'contact_form_success'
            });
          }
        } else if (data.validation) {
          this.errorMessageContainer.html(data.message).show();
          $.each(data.errors, (name) => {
            $(form).find(`[name="${name}"]`).addClass('is-invalid');
          });
          if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
              event: 'contact_form_error'
            });
          }
        } else {
          this.errorMessageContainer.html(data.message).show();
          if (typeof dataLayer !== 'undefined' && dataLayer) {
            dataLayer.push({
              event: 'contact_form_error'
            });
          }
        }
      }
    })
    */
    // console.error('!! Error: Ajax request missing and needs to implemented !!!')
    /* eslint-enable no-undef */
  }

  _showErrorMessage() {
    this._errorMessageContainer.innerHTML = 'Fehler beim Senden der Anfrage. Bitte versuchen Sie es später nochmal.'
    this._errorMessageContainer.style.display = 'block'
  }

  _hideStatusMessageContainers() {
    this._hideElement(this._errorMessageContainer)
    this._hideElement(this._successMessageContainer)
  }

  _hideElement(element) {
    element.style.display = 'none'
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const contactForms = SelectorEngine.find(Selector.CONTACTFORM)
  for (let i = 0, len = contactForms.length; i < len; i++) {
    ContactForm.initializeInterface(contactForms[i], Data.get(contactForms[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
defineJQueryPlugin(ContactForm)

export default ContactForm
