/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): map.js
 * --------------------------------------------------------------------------
 */
import * as L from 'leaflet/src/Leaflet'

import {
  defineJQueryPlugin
} from '../../bootstrap/js/src/util/index'
import {
  createElement,
  inView,
  append
} from './util/index'
import Data from '../../bootstrap/js/src/dom/data'
import Manipulator from '../../bootstrap/js/src/dom/manipulator'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'map'
const DATA_KEY = 'onma.map'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  type: 'openstreetmap',
  latitude: 0,
  longitude: 0,
  zoom: 13,
  language: 'en',
  lazy: true,
  closePopupOnClick: true
}

const DefaultType = {
  type: 'string',
  latitude: 'number',
  longitude: 'number',
  zoom: 'number',
  language: 'string',
  lazy: 'bool',
  closePopupOnClick: 'bool'
}

const Selector = {
  MAP: '[data-bs-plugin="map"]',
  MARKER: '[data-bs-plugin="map-marker"]'
}

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  SCROLL: `scroll${EVENT_KEY}`
}

const ClassName = {
  CONTAINER: 'map-container'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Map extends CommonComponent {
  constructor(element, config) {
    super(element, config)
    this._markers = []
    this._container = createElement('div', ClassName.CONTAINER)
    this._initialize()
  }

  // getters
  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private
  _initialize() {
    append(this._element, this._container)
    if (this._config.lazy) {
      if (inView(this._element)) {
        this._initializeLeafletMap()
      } else {
        EventHandler.on(window, Event.SCROLL, () => {
          if (inView(this._element)) {
            this._initializeLeafletMap()
          }
        })
      }
    } else {
      this._initializeLeafletMap()
    }
  }

  _initializeLeafletMap() {
    L.Map.include({
      openPopup(popup, latlng, options) {
        if (!(popup instanceof L.Popup)) {
          const content = popup

          popup = new L.Popup(options).setContent(content)
        }

        if (latlng) {
          popup.setLatLng(latlng)
        }

        if (this.hasLayer(popup)) {
          return this
        }

        // this.closePopup();
        this._popup = popup
        return this.addLayer(popup)
      }
    })
    this._initializeTitleLayer()
    this._initializeMap()
    this._initializeMakers()
    EventHandler.off(window, Event.SCROLL)
  }

  _initializeTitleLayer() {
    let attribution = ''
    let url = ''
    switch (this._config.type) {
      case 'greyscale':
        url = 'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'

        attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        break
      case 'openstreetmap':
      default: {
        switch (this._config.language.toLowerCase()) {
          case 'de': {
            url = 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png'
            break
          }

          case 'en':
          default: {
            url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            break
          }
        }

        attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        break
      }
    }

    this.titleLayer = L.tileLayer(url, { attribution })
  }

  _initializeMap() {
    this.map = L.map(this._container, {
      closePopupOnClick: this._config.closePopupOnClick
    }).setView([
      this._config.latitude,
      this._config.longitude
    ], this._config.zoom)

    this.titleLayer.addTo(this.map)
  }

  _initializeMakers() {
    const markers = SelectorEngine.find(Selector.MARKER)

    let config = {}

    markers.forEach(marker => {
      config = {
        latitude: 0,
        longitude: 0,
        openDefault: false,
        closeButton: true,
        autoClose: true,
        closeOnEscapeKey: true,
        ...Manipulator.getDataAttributes(marker)
      }
      const content = marker.innerHTML

      const popup = L.popup({
        closeButton: config.closeButton
      }).setContent(content)

      const maker = L.marker([config.latitude, config.longitude], {
        icon: L.divIcon({ className: 'custom-icon' })
      }).addTo(this.map)

      maker.bindPopup(popup)

      if (config.openDefault) {
        maker.openPopup()
      }

      this._markers.push(maker)
    })
  }

  _output(data) {
    /* eslint-disable no-console */
    console.log(data)
  }

  // static
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const maps = SelectorEngine.find(Selector.MAP)
  for (let i = 0, len = maps.length; i < len; i++) {
    Map.initializeInterface(maps[i], Data.get(maps[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
defineJQueryPlugin(Map)

export default Map
