/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): svg-map.js
 * --------------------------------------------------------------------------
 */

import {
  defineJQueryPlugin
} from '../../bootstrap/js/src/util/index'
import {
  createElement
} from './util/index'
import Data from '../../bootstrap/js/src/dom/data'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'svg-map'
const DATA_KEY = 'onma.svg-map'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  elementSelector: '.element',
  configUrl: 'map.json'
}

const DefaultType = {
  elementSelector: 'string',
  configUrl: 'string'
}

const Selector = {
  SVG_MAP: '[data-bs-plugin="svg-map"]'

}

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  CLICK: `click${EVENT_KEY}${DATA_API_KEY}`
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class SvgMap extends CommonComponent {
  constructor(element, config) {
    super(element, config)

    this._loadConfigFile(data => {
      this._data = data

      SelectorEngine.find(this._config.elementSelector, this._element).forEach(element => {
        const elementId = element.id
        const elementConfig = data[elementId]

        if (!elementConfig) {
          return
        }

        element.append(createElement('title', null, elementId))
        // reload svg ?? otherwise title is not visible
        element.innerHTML = String(element.innerHTML)

        EventHandler.on(element, Event.CLICK, () => {
          let dataElement = null
          Object.keys(elementConfig).forEach(label => {
            const value = elementConfig[label]
            if (typeof value === 'object') {
              Object.keys(value).forEach(sublabel => {
                dataElement = SelectorEngine.findOne(`[data-bs-svg-${label}-${sublabel}]`.toLowerCase())
                if (dataElement) {
                  dataElement.innerHTML = value[sublabel]
                }
              })
            } else {
              dataElement = SelectorEngine.findOne(`[data-bs-svg-${label}]`.toLowerCase())
              if (dataElement) {
                dataElement.innerHTML = value[label]
              }
            }
          })
          dataElement = SelectorEngine.findOne('[data-bs-svg-name]'.toLowerCase())
          if (dataElement) {
            dataElement.innerHTML = elementId
          }
        })
      })
    })
  }

  // getters
  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private

  _loadConfigFile(callback) {
    const request = new XMLHttpRequest()
    request.addEventListener('error', () => {
      throw new Error(`InteractiveForm failed to download config file: ${this._config.configUrl}`)
    })
    request.addEventListener('load', () => {
      if (request.status === 0 || request.status === 200) {
        const data = JSON.parse(request.response)
        Object.keys(data).forEach(key => {
          if (key.indexOf('_') === 0) {
            // json comment is with prefix underscore like "_comment"
            delete data[key]
          }
        })
        this._callback(callback, data)
      } else {
        throw new Error(`InteractiveForm failed to download config file: ${this._config.configUrl}`)
      }
    })
    request.open('get', this._config.configUrl, true)
    request.send()
  }

  // static
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const svgMaps = SelectorEngine.find(Selector.SVG_MAP)
  for (let i = 0, len = svgMaps.length; i < len; i++) {
    SvgMap.initializeInterface(svgMaps[i], Data.get(svgMaps[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
defineJQueryPlugin(SvgMap)

export default SvgMap
