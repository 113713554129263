/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): interactive-form.js
 * --------------------------------------------------------------------------
 */

import {
  defineJQueryPlugin
} from '../../bootstrap/js/src/util/index'
import {
  createElement,
  stringToHtml
} from './util/index'
import Data from '../../bootstrap/js/src/dom/data'
import Manipulator from '../../bootstrap/js/src/dom/manipulator'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'interactive-form'
const DATA_KEY = 'onma.interactive-form'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  useSessionStorage: false,
  configUrl: 'config.json',
  loadStartSlide: true,
  evaluationUrl: 'form.php',
  imagePathPrefix: '',
  mobilePopup: false
}

const DefaultType = {
  useSessionStorage: 'boolean',
  configUrl: 'string',
  loadStartSlide: 'boolean',
  evaluationUrl: 'string',
  imagePathPrefix: 'string',
  mobilePopup: 'boolean'
}

const Selector = {
  // BODY: 'body',
  INTERACTIVE_FORM: '[data-bs-plugin="interactive-form"]',
  SLIDE_WRAPPER: '.slide-wrapper',
  SLIDE_CONTAINER: '.slide-container',
  SLIDE: '.slide',
  SLIDE_BACK: '.slide-back',
  SLIDE_TO: '[data-bs-slide-to]',
  FIELDS: 'input,select,textarea',
  INPUT: 'input',
  TEXTAREA: 'textarea',
  INPUT_FILE: 'input[type="file"]',
  INPUT_RANGE: 'input[type="range"]',
  INPUT_TEXT: 'input[type="text"]',
  INPUT_CHECKBOX: 'input[type="checkbox"]',
  SUBMIT: 'button.submit',
  BUTTONS: '[data-bs-slide-to],button.submit'
}

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  HISTORY_STATE: `popstate${EVENT_KEY}${DATA_API_KEY}`,
  // TRANSITION_END: `transitionend${EVENT_KEY}${DATA_API_KEY}`,
  CHANGE: `change${EVENT_KEY}${DATA_API_KEY}`,
  CLICK: `click${EVENT_KEY}${DATA_API_KEY}`,
  KEYUP: `keyup${EVENT_KEY}${DATA_API_KEY}`,
  MOUSEMOVE: `mousemove${EVENT_KEY}${DATA_API_KEY}`
}

const Direction = {
  LEFT: 1,
  RIGHT: 2
}

/*
const ClassName = {
  MOBILE_POPUP: 'ix-form-mobile-popup'
}
*/

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class InteractiveForm extends CommonComponent {
  constructor(element, config) {
    super(element, config)
    /*
    if (config.mobilePopup && this._isDeviceMobile() && !$(element).parent().hasClass(ClassName.MOBILE_POPUP)) {
      const $mobilePopupElement = $(element).clone()
      const $mobilePopupWrapper = $(`<div class="${ClassName.MOBILE_POPUP}" />`)
      const $mobilePopupCloseButton = $('<div class="close">X</div>')
      $mobilePopupWrapper.append($mobilePopupCloseButton)
      $mobilePopupCloseButton.on('click', () => {
        $mobilePopupWrapper.remove()
      })
      $mobilePopupWrapper.prepend($mobilePopupElement)
      InteractiveForm._jQueryInterface.call($mobilePopupElement, $mobilePopupElement.data())
      $(Selector.BODY).prepend($mobilePopupWrapper)
    }
    */
    this._initialize()
  }

  // getters

  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private

  _initialize() {
    this._id = this._uniqueID()
    this._parent = this._element.parentNode
    this._formData = new FormData()
    this._history = window.history ? window.history : undefined
    this._slides = {}
    this._wrapper = SelectorEngine.findOne(Selector.SLIDE_WRAPPER, this._element)
    this._container = SelectorEngine.findOne(Selector.SLIDE_CONTAINER, this._wrapper)
    this._slides = SelectorEngine.find(Selector.SLIDE, this._container)

    this._isSliding = false

    if (this._history !== undefined) {
      EventHandler.on(window, Event.HISTORY_STATE, event => {
        const { id } = event.originalEvent.state

        if (this._id !== id) {
          event.preventDefault()
          return false
        }

        if (this._isSliding) {
          event.preventDefault()
          return false
        }

        const { slideName } = event.originalEvent.state
        const { slideIndex } = event.originalEvent.state

        let direction = Direction.LEFT

        if (this._currentSlideIndex > slideIndex) {
          direction = Direction.RIGHT
        }

        this._slideTo(slideName, direction, false)

        return true
      })
    }

    // binding events on default slide if available
    this._bindingSlideEvents(this._slides)

    // load config file
    this._loadConfigFile(data => {
      this._data = data
      if (this._config.loadStartSlide) {
        // render first slide
        this._container.append(this._buildSlideHtml('start'))
      }
    })

    // set the current slide at startup always to "start"
    this._currentSlideName = 'start'
    this._currentSlideIndex = 0

    if (this._history !== undefined) {
      // initialize the first state for start slide
      this._history.pushState({
        id: this._id,
        slideName: 'start',
        slideIndex: 0
      }, 'start')
    }
  }

  // functions to load data and build data

  _buildSlideHtml(slideName) {
    // if we've already a cached slideHtml for this slideName, just bind
    // events and return
    /*
    if (this._slides[slideName] !== undefined) {
      return this._bindingSlideEvents(
        [this._slides[slideName]]
      )
    }
    */

    if (!this._hasSlideConfig(slideName)) {
      throw new Error(`InteractiveForm failed to find config for slide: ${slideName}`)
    }

    const slideConfig = this._getSlideConfig(slideName)
    const slideType = slideConfig.type
    const slideClass = slideConfig.class
    const slideHtml = stringToHtml(`<div class="slide ${slideClass}" data-bs-name="${slideName}" data-bs-type="${slideType}"></div>`)

    if (slideConfig.buttonBack) {
      // when the slideName is not start we need to add an back button
      const slideBack = createElement('div', 'slide-back')
      slideHtml.append(slideBack)
    }

    let subheadline = stringToHtml('<h3 class="text-center">&nbsp;</h3>')
    if (slideConfig.subheadline) {
      subheadline = stringToHtml(`<h3 class="text-center">${slideConfig.subheadline}</h3>`)
    }

    slideHtml.append(subheadline)

    let headline = stringToHtml('<h2 class="text-center">&nbsp;</h2>')
    if (slideConfig.headline) {
      headline = stringToHtml(`<h2 class="text-center">${slideConfig.headline}</h2>`)
    }

    slideHtml.append(headline)

    if (slideConfig.percent) {
      slideHtml.append(this._buildProgressBarHtml(slideConfig.percent))
    }

    switch (slideType) {
      case 'box':
        slideHtml.append(this._buildSlideBoxHtml(slideConfig))
        break
      case 'select':
        slideHtml.append(this._buildSlideSelectHtml(slideConfig))
        break
      case 'input':
        slideHtml.append(this._buildSlideInputHtml(slideConfig))
        break
      case 'media':
        slideHtml.append(this._buildSlideMediaHtml(slideConfig))
        break
      case 'textarea':
        slideHtml.append(this._buildSlideTextareaHtml(slideConfig))
        break
      case 'checkbox':
        slideHtml.append(this._buildSlideCheckboxHtml(slideConfig))
        break
      case 'dropdown':
        slideHtml.append(this._buildSlideDropdownHtml(slideConfig))
        break
      case 'form':
        slideHtml.append(this._buildSlideFormHtml())
        break
      case 'message':
        slideHtml.append(this._buildSlideMessageHtml(slideConfig))
        break
      default:
        throw new Error(`InteractiveForm invalid type "${slideType}" for slide "${slideName}" found.`)
    }

    if (slideConfig.footer) {
      /*
      const $footer = $(
        '<div class="slide-footer">' +
          '<div class="row">' +
            '<div class="col-12 col-md-4">' +
              '<div class="icon-check"></div>Hausverkauf<br />zum Bestpreis' +
            '</div>' +
            '<div class="col-12 col-md-4">' +
              '<div class="icon-check"></div>Beratung durch<br />erfahrende Makler' +
            '</div>' +
            '<div class="col-12 col-md-4">' +
              '<div class="icon-check"></div>Schnell und<br />unkompliziert' +
            '</div>' +
          '</div>' +
        '</div>'
      )
      const $footer = $(
        '<div class="slide-footer">' +
          '<img src="images/lp/logos_desktop.png" class="img-fluid d-none d-md-block mx-auto" />' +
          '<img src="images/lp/logos_mobile.png" class="img-fluid d-block d-md-none" />' +
        '</div>'
      )
      $slideHtml.append($footer)
      */
    }

    const button = SelectorEngine.findOne(Selector.BUTTONS, slideHtml)
    if (button) {
      if (this._isSlideValid(slideHtml)) {
        button.removeAttribute('disabled')
      } else {
        button.setAttribute('disabled', 'disabled')
      }
    }

    // save generated html for this slide for next time and bind events
    this._bindingSlideEvents([slideHtml])
    this._slides[slideName] = slideHtml
    return slideHtml
  }

  _buildSlideBoxHtml(slideConfig) {
    // const grids = 12;
    const container = createElement('div')
    if (slideConfig.buttons && slideConfig.buttons.length > 0) {
      const buttons = stringToHtml('<div class="row buttons d-none d-md-flex justify-content-md-center" />')
      const buttonsSmall = stringToHtml('<div class="row buttons d-flex d-md-none" />')
      // const switchToOtherLayoutCount = 5
      // const maxColumnsBefore5 = 3
      // const maxColumnsAfter5 = 3
      // const buttonCount = slideConfig.buttons.length
      // let columnValue = Math.floor(grids / buttonCount)
      // const columnValue = 3
      // const buttonNewRowApply = 4
      // const buttonRowMaxCount = 3

      slideConfig.buttons.forEach(_button => {
        /*
        if (buttonCount >= switchToOtherLayoutCount) {
          if (columnValue < maxColumnsAfter5) {
            columnValue = maxColumnsAfter5
          }
        } else if (columnValue < maxColumnsBefore5) {
          columnValue = maxColumnsBefore5
        }
        */

        // const button = stringToHtml(`<div class="col-md-${columnValue} col-sm-6 col-6 button" data-bs-slide-to="${_button.link}" data-bs-value="${_button.name}" />`)
        const button = stringToHtml(`<div class="col-sm-6 col-6 button" data-bs-slide-to="${_button.link}" data-bs-value="${_button.name}" />`)
        const buttonInner = createElement('div', 'button-inner')
        if (_button.icon) {
          buttonInner.append(stringToHtml(`<img src="${this._config.imagePathPrefix}${_button.icon}" class="img-fluid" />`))
        }

        if (_button.name) {
          buttonInner.append(stringToHtml(`<h4>${_button.name}</h4>`))
        }

        button.append(buttonInner)
        /*
        if (buttonCount > buttonNewRowApply && i % buttonRowMaxCount === 0 && i > 0) {
          container.append(buttons)
          buttons = stringToHtml('<div class="row buttons d-none d-md-flex justify-content-md-center" />')
        }
        */

        buttons.append(button)
        buttonsSmall.append(button.cloneNode(true))
      })

      container.append(buttons)
      container.append(buttonsSmall)
    }

    if (slideConfig.button && slideConfig.button.name && slideConfig.button.link) {
      container.append(`<button class="additional-button btn btn-primary w-100" data-bs-slide-to="${slideConfig.button.link}">${slideConfig.button.name}</button>`)
    }

    return container
  }

  _buildSlideSelectHtml(slideConfig) {
    const container = createElement('div')

    let row = createElement('div', 'row')

    let largeColumn = stringToHtml('<div class="col-sm-8 col-12 mb-5 mb-sm-0 align-self-center" />')
    let smallColumn = stringToHtml('<div class="col-sm-4 col-12" />')

    const largeColumn1 = largeColumn.cloneNode(true)

    largeColumn1.append(this._buildSlideSelectRangeHtml(
      slideConfig.text,
      slideConfig.range.from,
      slideConfig.range.to,
      slideConfig.default
    ))

    row.append(largeColumn1)

    const smallColumn1 = smallColumn.cloneNode(true)

    smallColumn1.append(stringToHtml(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`))

    container.append(row)

    row = createElement('div', 'row')

    largeColumn = stringToHtml('<div class="col-sm-8 col-12 mb-3 mb-sm-0" />')
    smallColumn = stringToHtml('<div class="col-sm-4 col-12 align-self-end" />')

    const largeColumn2 = largeColumn.cloneNode(true)

    largeColumn2.append(this._buildSlideSelectInputHtml(
      slideConfig.default,
      slideConfig.unit,
      slideConfig.range.from,
      slideConfig.range.to
    ))

    row.append(largeColumn2)

    const smallColumn2 = smallColumn.cloneNode(true)

    smallColumn2.append(stringToHtml(`<button class="btn btn-primary" disabled data-bs-slide-to="${slideConfig.link}">Weiter</button>`))

    row.append(smallColumn2)

    container.append(row)

    return container
  }

  _buildSlideSelectRangeHtml(text, min, max, value) {
    const container = createElement('div', 'form-group')

    if (text) {
      container.append(createElement('label', null, text))
    }

    const input = stringToHtml(`<input type="range" class="select-range" min="${min}" max="${max}" value="${value}">`)
    container.append(input)

    this._setSliderRangeBackground(input)

    return container
  }

  _buildSlideSelectInputHtml(value, unit, min, max) {
    const container = createElement('div', 'form-group')

    const label = createElement('label', null, 'Manuell eintippen')
    container.append(label)

    const inputGroup = createElement('div', 'input-group')

    const input = stringToHtml(`<input type="text" required class="form-control" min="${min}" max="${max}" value="${value}">`)
    inputGroup.append(input)

    if (unit) {
      const inputUnit = stringToHtml(`<div class="input-group-append"><span class="input-group-text">${unit}</span></div>`)
      inputGroup.append(inputUnit)
    }

    container.append(inputGroup)
    return container
  }

  _buildSlideInputHtml(slideConfig) {
    const container = createElement('div')

    const row = stringToHtml('<div class="row align-items-center" />')

    if (slideConfig.icon) {
      const iconColumn = stringToHtml('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
      const icon = stringToHtml(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

      iconColumn.append(icon)
      row.append(iconColumn)
    }

    const inputColumn = stringToHtml('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

    if (slideConfig.input) {
      const inputConfig = slideConfig.input

      if (inputConfig.title) {
        inputColumn.append(stringToHtml(`<h4>${inputConfig.title}</h4>`))
      }

      const input = stringToHtml(`<input type="text" required class="form-control mb-3" value="" placeholder="${inputConfig.placeholder}">`)

      if (inputConfig.min) {
        input.setAttribute('min', inputConfig.min)
      }

      if (inputConfig.max) {
        input.setAttribute('max', inputConfig.max)
      }

      if (inputConfig.pattern) {
        input.setAttribute('pattern', inputConfig.pattern)
      }

      inputColumn.append(input)
    }

    inputColumn.append(stringToHtml(`<button class="btn btn-primary w-100" disabled data-bs-slide-to="${slideConfig.link}">Weiter</button>`))
    row.append(inputColumn)
    container.append(row)
    return container
  }

  _buildSlideMediaHtml(slideConfig) {
    const container = createElement('div')

    if (slideConfig.title) {
      container.append(stringToHtml(`<h4>${slideConfig.title}</h4>`))
    }

    if (slideConfig.infotext) {
      container.append(stringToHtml(`<h5 class="text-center text-secondary">${slideConfig.infotext}</h5>`))
    }

    const row = stringToHtml('<div class="row align-items-center" />')

    const imageColumn1 = stringToHtml('<div class="col-lg-6 col-md-6 col-12 text-center" />')

    const image1 = stringToHtml(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel1.png">`)

    imageColumn1.append(image1)

    const inputColumn1 = stringToHtml('<div class="col-lg-6 col-md-6 col-12" />')

    const input1 = stringToHtml('<input type="file" class="form-control mb-3 w-lg-75" value="">')

    inputColumn1.append(input1)

    const imageColumn2 = stringToHtml('<div class="col-lg-6 col-md-6 col-12 text-center" />')

    const image2 = stringToHtml(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel2.png">`)

    imageColumn2.append(image2)

    const inputColumn2 = stringToHtml('<div class="col-lg-6 col-md-6 col-12" />')

    const input2 = stringToHtml('<input type="file" class="form-control mb-3 w-lg-75" value="">')

    inputColumn2.append(input2)

    const imageColumn3 = stringToHtml('<div class="col-lg-6 col-md-6 col-12 text-center" />')

    const image3 = stringToHtml(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel3.png">`)

    imageColumn3.append(image3)

    const inputColumn3 = stringToHtml('<div class="col-lg-6 col-md-6 col-12" />')

    const input3 = stringToHtml('<input type="file" class="form-control mb-3 w-lg-75" value="">')

    inputColumn3.append(input3)

    const imageColumn4 = stringToHtml('<div class="col-lg-6 col-md-6 col-12 text-center" />')

    const image4 = stringToHtml(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel4.png">`)

    imageColumn4.append(image4)

    const inputColumn4 = stringToHtml('<div class="col-lg-6 col-md-6 col-12" />')

    const input4 = stringToHtml('<input type="file" class="form-control mb-3 w-lg-75" value="">')

    inputColumn4.append(input4)

    const imageColumn5 = stringToHtml('<div class="col-lg-6 col-md-6 col-12 text-center" />')

    const image5 = stringToHtml(`<img src="${this._config.imagePathPrefix}images/dummy/beispiel5.png">`)

    imageColumn5.append(image5)

    const inputColumn5 = stringToHtml('<div class="col-lg-6 col-md-6 col-12" />')

    const input5 = stringToHtml('<input type="file" class="form-control mb-3 w-lg-75" value="">')

    inputColumn5.append(input5)

    const buttonColumn = stringToHtml('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

    buttonColumn.append(stringToHtml(`<button class="btn btn-primary w-100" disabled data-bs-slide-to="${slideConfig.link}">Weiter</button>`))

    row.append(imageColumn1)
    row.append(inputColumn1)
    row.append(imageColumn2)
    row.append(inputColumn2)
    row.append(imageColumn3)
    row.append(inputColumn3)
    row.append(imageColumn4)
    row.append(inputColumn4)
    row.append(imageColumn5)
    row.append(inputColumn5)
    row.append(buttonColumn)

    container.append(row)

    return container
  }

  _buildSlideTextareaHtml(slideConfig) {
    const container = createElement('div')

    const row = stringToHtml('<div class="row align-items-center" />')

    if (slideConfig.icon) {
      const iconColumn = stringToHtml('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
      const icon = stringToHtml(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

      iconColumn.append(icon)
      row.append(iconColumn)
    }

    const inputColumn = stringToHtml('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

    if (slideConfig.textarea) {
      const inputConfig = slideConfig.textarea

      if (inputConfig.title) {
        inputColumn.append(stringToHtml(`<h4>${inputConfig.title}</h4>`))
      }

      // const $input = $(`<input type="text" required class="form-control mb-3" value="" placeholder="${inputConfig.placeholder}">`)
      const input = stringToHtml(`<textarea required class="form-control mb-3" placeholder="${inputConfig.placeholder}"></textarea>`)

      if (inputConfig.rows) {
        input.setAttribute('rows', inputConfig.rows)
      }

      if (inputConfig.maxlength) {
        input.setAttribute('maxlength', inputConfig.maxlength)
      }

      if (inputConfig.minlength) {
        input.setAttribute('minlength', inputConfig.minlength)
      }

      if (inputConfig.pattern) {
        input.setAttribute('pattern', inputConfig.pattern)
      }

      inputColumn.append(input)
    }

    inputColumn.append(stringToHtml(`<button class="btn btn-primary w-100" disabled data-bs-slide-to="${slideConfig.link}">Weiter</button>`))
    row.append(inputColumn)
    container.append(row)
    return container
  }

  _buildSlideCheckboxHtml(slideConfig) {
    const container = createElement('div')

    const row = stringToHtml('<div class="row align-items-center" />')

    if (slideConfig.icon) {
      const iconColumn = stringToHtml('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
      const icon = stringToHtml(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

      iconColumn.append(icon)
      row.append(iconColumn)
    }

    const inputColumn = stringToHtml('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

    if (slideConfig.checkbox) {
      const inputConfig = slideConfig.checkbox

      if (inputConfig.title) {
        inputColumn.append(`<h4>${inputConfig.title}</h4>`)
      }

      const input = stringToHtml('<div class="checkbox-container mb-4"></div>')

      if (inputConfig.options) {
        Object.keys(inputConfig.options).forEach(value => {
          const label = inputConfig.options[value]
          input.append(stringToHtml(`<div class="my-2"><input class="mr-2" type="checkbox" id="${value}" value="${label}"><label class="d-inline" for="${value}">${label}</label></div>`))
        })
      }

      inputColumn.append(input)
    }

    inputColumn.append(stringToHtml(`<button class="btn btn-primary w-100" disabled data-bs-slide-to="${slideConfig.link}">Weiter</button>`))
    row.append(inputColumn)
    container.append(row)
    return container
  }

  _buildSlideDropdownHtml(slideConfig) {
    const container = createElement('div')

    const row = stringToHtml('<div class="row align-items-center" />')

    if (slideConfig.icon) {
      const iconColumn = stringToHtml('<div class="col-lg-3 col-md-6 col-12 d-none d-md-block" />')
      const icon = stringToHtml(`<img src="${this._config.imagePathPrefix}${slideConfig.icon}" class="img-fluid" />`)

      iconColumn.append(icon)
      row.append(iconColumn)
    }

    const inputColumn = stringToHtml('<div class="col-lg-6 col-md-6 offset-lg-3 col-12" />')

    if (slideConfig.dropdown) {
      const inputConfig = slideConfig.dropdown

      if (inputConfig.title) {
        inputColumn.append(stringToHtml(`<h4>${inputConfig.title}</h4>`))
      }

      // const $input = $(`<input type="text" required class="form-control mb-3" value="" placeholder="${inputConfig.placeholder}">`)
      const input = stringToHtml('<select size="1" required class="form-control mb-3"></select>')

      if (inputConfig.options) {
        Object.keys(inputConfig.options).forEach(value => {
          const label = inputConfig.options[value]
          input.append(stringToHtml(`<option value="${value}">${label}</option>`))
        })
      }

      inputColumn.append(input)
    }

    inputColumn.append(stringToHtml(`<button class="btn btn-primary w-100" disabled data-bs-slide-to="${slideConfig.link}">Weiter</button>`))
    row.append(inputColumn)
    container.append(row)
    return container
  }

  _buildSlideFormHtml() {
    const container = createElement('div', 'form-content')
    const row = createElement('div', 'row')
    const leftColumn = stringToHtml('<div class="col-lg-6 col-12" />')

    const formGroup = stringToHtml('<div class="form-group" />')

    const radioButton1 = stringToHtml('<div class="custom-control custom-radio custom-control-inline" />')

    radioButton1.append(
      stringToHtml('<input type="radio" class="custom-control-input" required id="salutation2" name="salutation" value="Frau"><label class="custom-control-label" for="salutation2">Frau</label>')
    )

    formGroup.append(radioButton1)

    const radioButton2 = stringToHtml('<div class="custom-control custom-radio custom-control-inline" />')

    radioButton2.append(
      stringToHtml('<input type="radio" class="custom-control-input" required id="salutation1" name="salutation" value="Herr"><label class="custom-control-label" for="salutation1">Herr</label>')
    )

    formGroup.append(radioButton2)

    leftColumn.append(formGroup)

    leftColumn.append(
      this._wrapWithFormGroup('<input type="text" required class="form-control" placeholder="Vorname*" name="firstName" />')
    )

    leftColumn.append(
      this._wrapWithFormGroup('<input type="text" required class="form-control" placeholder="Nachname*" name="lastName" />')
    )

    leftColumn.append(
      this._wrapWithFormGroup(
        '<select size="5" class="form-control selectpicker" name="weekdays" title="Für Termin Wochentage auswählen (Mehrfachauswahl möglich)" multiple>' +
          '<option value="Mo">Montag</option>' +
          '<option value="Di">Dienstag</option>' +
          '<option value="Mi">Mittwoch</option>' +
          '<option value="Do">Donnerstag</option>' +
          '<option value="Fr">Freitag</option>' +
        '</select>'
      )
    )

    row.append(leftColumn)

    const rightColumn = stringToHtml('<div class="col-lg-6 col-12" />')

    rightColumn.append(
      stringToHtml('<div class="form-group d-none d-lg-block" />')
    )

    rightColumn.append(
      this._wrapWithFormGroup('<input type="email" required class="form-control" placeholder="E-Mail-Adresse*" name="email" />')
    )

    rightColumn.append(
      this._wrapWithFormGroup('<input type="text" class="form-control" placeholder="Telefonnummer" name="phone" />')
    )

    rightColumn.append(
      this._wrapWithFormGroup(
        '<select size="5" class="form-control selectpicker" name="times" multiple title="Für Termin Zeitraum auswählen (Mehrfachauswahl möglich)">' +
          '<option value="Vormittags">Vormittags</option>' +
          '<option value="Nachmittags">Nachmittags</option>' +
        '</select>'
      )
    )

    row.append(rightColumn)

    container.append(row)

    // new row for submit button

    const row2 = createElement('div', 'row')
    const leftColumn2 = stringToHtml('<div class="col-lg-6 col-12" />')

    leftColumn2.append(
      this._wrapWithFormGroup(
        '<div>' +
          '<small class="text-secondary">Mit dem Absenden der Antworten erklären Sie sich mit unseren Datenschutzbestimmungen und AGB einverstanden.</small>' +
        '</div>'
      )
    )
    row2.append(leftColumn2)

    const rightColumn2 = stringToHtml('<div class="col-lg-6 col-12" />')
    rightColumn2.append(stringToHtml('<button class="btn btn-primary w-100 submit" disabled>Senden</button>'))

    row2.append(rightColumn2)

    container.append(row2)

    return container
  }

  _buildSlideMessageHtml(slideConfig) {
    const container = createElement('div')
    container.append(stringToHtml(`<p>${slideConfig.message}</p>`))
    return container
  }

  _buildProgressBarHtml(progress) {
    const container = createElement('div', 'progress')
    const bar = stringToHtml(`<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="${progress}" aria-valuemin="0" aria-valuemax="100" style="width: ${progress}%" />`)
    container.append(bar)
    return container
  }

  _wrapWithFormGroup(input) {
    const formGroup = createElement('div', 'form-group')
    formGroup.append(stringToHtml(input))
    return formGroup
  }

  _bindingSlideEvents(slides) {
    return slides.forEach(slide => {
      SelectorEngine.find(Selector.SLIDE_TO, slide).forEach(slideToElement => {
        EventHandler.on(slideToElement, Event.CLICK, event => {
          event.preventDefault()

          const target = event.currentTarget
          const slideName = Manipulator.getDataAttribute(target, 'slideTo')

          // const $slideBefore = this._getCurrentSlide()
          const slideNameBefore = this._currentSlideName
          const slideTypeBefore = this._getCurrentSlideType()

          let slideValueBefore = ''

          slideValueBefore = slideTypeBefore === 'box' ? Manipulator.getDataAttribute(target, 'value') : this._getCurrentSlideValue()

          this._slideTo(slideName, Direction.LEFT, true, () => {
            this._setValue(slideNameBefore, slideValueBefore)
          })

          return false
        })
      })

      SelectorEngine.find(Selector.SLIDE_BACK, slide).forEach(slideBackElement => {
        EventHandler.on(slideBackElement, Event.CLICK, event => {
          event.preventDefault()
          if (this._history === true) {
            this._history.back()
          }

          return false
        })
      })

      SelectorEngine.find(Selector.INPUT_RANGE, slide).forEach(inputRangeElement => {
        EventHandler.on(inputRangeElement, Event.CHANGE, event => {
          event.preventDefault()
          const { target } = event
          SelectorEngine.findOne(Selector.INPUT_TEXT, slide).value = target.value
          return false
        })
        EventHandler.on(inputRangeElement, Event.MOUSEMOVE, event => {
          const { target } = event
          this._setSliderRangeBackground(target)
        })
      })

      SelectorEngine.find(Selector.INPUT_TEXT, slide).forEach(inputTextElement => {
        EventHandler.on(inputTextElement, Event.KEYUP, event => {
          event.preventDefault()
          const { target } = event
          const range = SelectorEngine.findOne(Selector.INPUT_RANGE, slide)
          range.value = target.value
          this._setSliderRangeBackground(range)
          return false
        })
      })

      SelectorEngine.find(Selector.FIELDS, slide).forEach(fieldsElement => {
        EventHandler.on(fieldsElement, `${Event.KEYUP} ${Event.CHANGE}`, event => {
          event.preventDefault()
          if (this._isSlideValid(slide, true)) {
            SelectorEngine.findOne(Selector.BUTTONS, slide).removeAttribute('disabled')
          } else {
            SelectorEngine.findOne(Selector.BUTTONS, slide).setAttribute('disabled', 'disabled')
          }

          return false
        })
      })

      SelectorEngine.find(Selector.SUBMIT, slide).forEach(submitElement => {
        EventHandler.on(submitElement, Event.CLICK, event => {
          event.preventDefault()
          SelectorEngine.find(Selector.FIELDS, slide).forEach(field => {
            const fieldName = field.getAttribute('name')
            const fieldValue = field.value
            this._formData.set(`formValues[${fieldName}]`, fieldValue)
          })

          const request = new XMLHttpRequest()
          request.addEventListener('error', () => {
            this._slideTo('error')
          })
          request.addEventListener('load', () => {
            if (request.status === 200) {
              this._slideTo('success', null, false, () => {
                //  clear storage after success
                this._formData = new FormData()
              })
            } else {
              this._slideTo('error')
            }
          })
          request.open('post', this._config.evaluationUrl, true)
          request.send(this._formData)
          return false
        })
      })
    })
  }

  // functions to slide

  _slideTo(slideName, direction, history, callback) {
    if (this._isSliding) {
      return
    }

    this._isSliding = true
    const slide = this._buildSlideHtml(slideName)
    const slideIndex = this._currentSlideIndex
    const afterSlideCallback = () => {
      if (this._history === true) {
        this._history.pushState({
          id: this._id,
          slideName,
          slideIndex
        }, slideName)
      }

      if (direction === Direction.LEFT) {
        this._currentSlideIndex++
      } else {
        this._currentSlideIndex--
      }

      this._currentSlideName = slideName
      this._isSliding = false
      this._callback(callback)
    }

    switch (direction) {
      case Direction.RIGHT:
        this._slideToRight(slide, afterSlideCallback)
        break
      case Direction.LEFT:
      default:
        this._slideToLeft(slide, afterSlideCallback)
        break
    }
  }

  _slideToLeft(slide, callback) {
    this._container.append(slide)
    EventHandler.on(this._container, 'transitionend', e => {
      if (e.propertyName !== 'left') {
        return
      }

      this._isSliding = false
      EventHandler.off(this._container, 'transitionend')
      this._container.classList.remove('slide-container--transition')
      const firstChild = this._container.children[0]
      firstChild.remove()
      this._container.style.left = 0
      this._callback(callback)
    })
    setTimeout(() => {
      this._container.classList.add('slide-container--transition')
      this._container.style.left = '-100%'
    }, 100)
  }

  _slideToRight(slide, callback) {
    this._container.insertBefore(slide, this._container.firstChild)
    this._container.style.left = '-100%'
    // little timeout after setting left to -100%
    setTimeout(() => {
      EventHandler.one(this._container, 'transitionend', e => {
        this._isSliding = false
        EventHandler.off(this._container, 'transitionend')
        if (e.propertyName !== 'right') {
          return
        }

        this._container.classList.remove('slide-container--transition')
        this._container.lastChild.remove()
        this._callback(callback)
      })
      this._container.classList.add('slide-container--transition')
      this._container.style.left = '0px'
    }, 100)
  }

  _isSlideValid(slide, mark) {
    let isValid = true
    const type = Manipulator.getDataAttribute(slide, 'type')
    switch (type) {
      case 'checkbox':
        isValid = false
        SelectorEngine.find(Selector.INPUT_CHECKBOX, slide).forEach(input => {
          if (input.checked) {
            isValid = true
          }
        })
        break
      default:
        SelectorEngine.find(Selector.FIELDS, slide).forEach(input => {
          if (input.checkValidity()) {
            input.classList.remove('is-invalid')
          } else {
            isValid = false
            input.reportValidity()
            if (mark === true) {
              input.classList.add('is-invalid')
            }
          }
        })
        break
    }

    return isValid
  }

  _isCurrentSlideValid(mark) {
    const slide = this._getCurrentSlide()
    return this._isSlideValid(slide, mark)
  }

  _getCurrentSlideValue() {
    let value = null
    const slide = this._getCurrentSlide()
    const type = Manipulator.getDataAttribute(slide, 'type')
    switch (type) {
      case 'select':
        value = SelectorEngine.findOne(Selector.INPUT_RANGE, slide).value
        break
      case 'input':
        value = SelectorEngine.findOne(Selector.INPUT, slide).value
        break
      case 'checkbox':
        value = []
        SelectorEngine.find(Selector.INPUT_CHECKBOX, slide).forEach(input => {
          if (input.checked) {
            value.push(input.value)
          }
        })
        break
      case 'textarea':
        value = SelectorEngine.findOne(Selector.TEXTAREA, slide).value
        break
      case 'media':
        value = []
        SelectorEngine.find(Selector.INPUT_FILE, slide).forEach(input => {
          if (input.files[0]) {
            value.push(input.files[0])
          }
        })
        break
      case 'form':
        // value = $slide.find(Selector.INPUT).val()
        break
      default:
        break
    }

    return value
  }

  _getCurrentSlideType() {
    const slide = this._getCurrentSlide()
    const type = Manipulator.getDataAttribute(slide, 'type')
    return type
  }

  _getCurrentSlide() {
    return SelectorEngine.findOne(Selector.SLIDE, this._container)
  }

  _getValue(name) {
    this._formData.get(name)
  }

  _setValue(name, value) {
    if (Array.isArray(value)) {
      this._formData.delete(`${name}[]`)
      value.forEach(xvalue => {
        this._formData.append(`${name}[]`, xvalue)
      })
    } else {
      this._formData.set(name, value)
    }
  }

  // standard functions

  _setSliderRangeBackground(rangeSlider) {
    const percentMultiplier = 100
    const value = (rangeSlider.value - rangeSlider.getAttribute('min')) / (rangeSlider.getAttribute('max') - rangeSlider.getAttribute('min'))
    const percent = value * percentMultiplier

    rangeSlider.style.backgroundImage = `linear-gradient(to left bottom, #f7a71d 0%, #f7a71d ${percent}%, #b4b4b4 ${percent}%, #b4b4b4 100%)`
    // rangeSlider.style.backgroundImage = `-webkit-gradient(linear, left top, right top, color-stop(${percent}%, #f7a71d), color-stop(${percent}%, #b4b4b4))`
  }
  // functions to load config

  _loadConfigFile(callback) {
    const request = new XMLHttpRequest()
    request.addEventListener('error', () => {
      throw new Error(`InteractiveForm failed to download config file: ${this._config.configUrl}`)
    })
    request.addEventListener('load', () => {
      if (request.status === 0 || request.status === 200) {
        const data = JSON.parse(request.response)
        Object.keys(data).forEach(key => {
          if (key.indexOf('_') === 0) {
            // json comment is with prefix underscore like "_comment"
            delete data[key]
          }
        })
        this._callback(callback, data)
      } else {
        throw new Error(`InteractiveForm failed to download config file: ${this._config.configUrl}`)
      }
    })
    request.open('get', this._config.configUrl, true)
    request.send()
  }

  _getSlideConfig(slideName) {
    return this._data === undefined ? undefined : this._data[slideName]
  }

  _hasSlideConfig(slideName) {
    return this._getSlideConfig(slideName) !== undefined
  }

  _uniqueID() {
    return Math.floor(Math.random() * Date.now())
  }

  _isDeviceMobile() {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch {
      return false
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const interactiveForms = SelectorEngine.find(Selector.INTERACTIVE_FORM)
  for (let i = 0, len = interactiveForms.length; i < len; i++) {
    InteractiveForm.initializeInterface(interactiveForms[i], Data.get(interactiveForms[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
defineJQueryPlugin(InteractiveForm)

export default InteractiveForm
