/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): util/index.js
 * --------------------------------------------------------------------------
 */

const createElement = (tagName, className, content) => {
  const element = document.createElement(tagName)
  if (className) {
    element.className = className
  }

  if (content) {
    element.innerHTML = content
  }

  return element
}

const stringToHtml = string => {
  const document = (new DOMParser()).parseFromString(string, 'text/html')
  return document.body.firstChild
}

const inView = (element, offset) => {
  if (!offset) {
    offset = 0
  }
  /*
  For advanced carousel curtains

  return window.pageYOffset + document.documentElement.clientHeight >= element.offsetTop + offset
  */

  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const offset = element => {
  const rect = element.getBoundingClientRect()

  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  }
}

const prepend = (parent, child) => {
  parent.insertBefore(child, parent.firstChild)
}

const append = (parent, child) => {
  parent.append(child)
}

const getStorageObject = useSessionStorage => {
  if (window.localStorage !== undefined && !useSessionStorage) {
    return window.localStorage
  }

  if (window.sessionStorage !== undefined) {
    return window.sessionStorage
  }
}

const extend = (...args) => {
  const extended = {}
  let deep = false
  let i = 0
  const { length } = args

  if (Object.prototype.toString.call(args[0]) === '[object Boolean]') {
    deep = args[0]
    i++
  }

  const merge = function (obj) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = extend(true, extended[prop], obj[prop])
        } else {
          extended[prop] = obj[prop]
        }
      }
    }
  }

  for (; i < length; i++) {
    const obj = args[i]
    merge(obj)
  }

  return extended
}

export {
  createElement,
  stringToHtml,
  inView,
  offset,
  prepend,
  append,
  getStorageObject,
  extend
}
