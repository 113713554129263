/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.0.1): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

// import Alert from '../bootstrap/js/src/alert'
// import Button from '../bootstrap/js/src/button'
// import Carousel from '../bootstrap/js/src/carousel'
import Collapse from '../bootstrap/js/src/collapse'
import Dropdown from '../bootstrap/js/src/dropdown'
// import Modal from '../bootstrap/js/src/modal'
// import Popover from '../bootstrap/js/src/popover'
// import ScrollSpy from '../bootstrap/js/src/scrollspy'
// import Tab from '../bootstrap/js/src/tab'
// import Toast from '../bootstrap/js/src/toast'
// import Tooltip from '../bootstrap/js/src/tooltip'

import AdvancedCarousel from './src/advanced-carousel'
// import Animate from './src/animate'
// import CompareImages from './src/compare-images'
// import CountUp from './src/count-up'
import ContactForm from './src/contact-form'
// import ImageHotspot from './src/image-hotspot'
import InteractiveForm from './src/interactive-form'
import LazyLoad from 'vanilla-lazyload'
import Map from './src/map'
import SvgMap from './src/svg-map'
// import NavbarScrollTransition from './src/navbar-scroll-transition'
// import Parallax from './src/parallax'
// import PrefillForm from './src/prefill-form'
// import RangeSlider from './src/range-slider'
// import Scroll from './src/scroll'
// import SvgAnimation from './src/svg-animation'

export default {
  // Alert,
  // Button,
  // Carousel,
  Collapse,
  Dropdown,
  // Modal,
  // Popover,
  // ScrollSpy,
  // Tab,
  // Toast,
  // Tooltip,

  AdvancedCarousel,
  // Animate,
  // CompareImages,
  // CountUp,
  ContactForm,
  // ImageHotspot,
  InteractiveForm,
  LazyLoad,
  Map,
  SvgMap
  // NavbarScrollTransition,
  // Parallax,
  // PrefillForm,
  // RangeSlider,
  // Scroll,
  // SvgAnimation
}
