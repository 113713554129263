/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): advanced-carousel.js
 * --------------------------------------------------------------------------
 */

import {
  defineJQueryPlugin
} from '../../bootstrap/js/src/util/index'
import {
  createElement,
  inView,
  append,
  prepend,
  extend
} from './util/index'
import Data from '../../bootstrap/js/src/dom/data'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

import Swiper from 'swiper'

const NAME = 'advanced-carousel'
const DATA_KEY = 'onma.advanced-carousel'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  direction: 'horizontal',
  lazy: true,
  loop: true,
  autoHeight: true,
  autoplay: false,
  freeMode: false,
  freeModeMomentum: false,
  autoplayDelay: 5000,
  parallax: false,
  keyboard: true,
  spaceBetween: 30,
  slidesPerView: 4,
  loadPrevNextAmount: 3,
  curtains: false,
  curtainsOffset: 200,
  curtainsDuration: 400,
  curtainsEndOffset: 300,
  breakpoints: {
    992: {
      spaceBetween: 30,
      slidesPerView: 1,
      loadPrevNextAmount: 1
    }
  },

  // components
  navigation: {
    active: false,
    hideOnClick: false
  },

  pagination: {
    active: false,
    type: 'bullets',
    clickable: true
  },

  scrollbar: {
    active: false,
    hide: true,
    draggable: false,
    snapOnRelease: false,
    dragSize: 'auto'
  }
}

const DefaultType = {
  direction: 'string',
  lazy: 'boolean',
  loop: 'boolean',
  autoHeight: 'boolean',
  autoplay: 'boolean',
  freeMode: 'boolean',
  freeModeMomentum: 'boolean',
  autoplayDelay: 'number',
  parallax: 'boolean',
  keyboard: 'boolean',
  spaceBetween: 'number',
  slidesPerView: 'number',
  curtains: 'boolean',
  curtainsOffset: 'number',
  curtainsDuration: 'number',
  curtainsEndOffset: 'number',
  breakpoints: 'object',

  // components
  navigation: {
    active: 'boolean',
    hideOnClick: 'boolean'
  },

  pagination: {
    active: 'boolean',
    type: 'string',
    clickable: 'boolean'
  },

  scrollbar: {
    active: 'boolean',
    hide: 'boolean',
    draggable: 'boolean',
    snapOnRelease: 'boolean',
    dragSize: 'string'
  }
}

const Selector = {
  DATA_RIDE: '[data-bs-ride="advanced-carousel"]',
  NAVIGATION_PREV: '.swiper-button-next',
  NAVIGATION_NEXT: '.swiper-button-prev',
  PAGINATION: '.swiper-pagination',
  SCROLLBAR: '.swiper-scrollbar',
  SLIDE: '.swiper-slide',
  // CURTAINS: '.swiper-slide-curtains',
  CURTAINS_BEFORE: '.swiper-slide-curtains-before',
  CURTAINS_AFTER: '.swiper-slide-curtains-after'
}

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  SCROLL: `scroll${EVENT_KEY}${DATA_API_KEY}`,
  SLIDE_CHANGED: 'slideChange'
}

const ClassName = {
  CURTAINS: 'swiper-slide-curtains',
  CURTAINS_BEFORE: 'swiper-slide-curtains-before',
  CURTAINS_AFTER: 'swiper-slide-curtains-after'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
class AdvancedCarousel extends CommonComponent {
  constructor(element, config) {
    super(element, config)
    this._slides = SelectorEngine.find(Selector.SLIDE, this._element)
    if (this._config.lazy) {
      this._config.preloadImages = false
      this._config.loadOnTransitionStart = true
      this._config.watchSlidesVisibility = true

      if (inView(this._element)) {
        this._initialize()
      } else {
        EventHandler.on(window, Event.SCROLL, () => {
          if (this._isInitialized) {
            return
          }

          if (inView(this._element)) {
            this._initialize()
          }
        })
      }
    } else {
      this._initialize()
    }
  }

  // getters
  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private
  _initialize() {
    this._isInitialized = true
    this._buildNavigation()
    this._buildPagination()
    this._buildScrollbar()
    this._buildCurtains()
    this.swiper = new Swiper(this._element, this._config)

    if (this._config.lazy) {
      this.swiper.on(Event.SLIDE_CHANGED, function () {
        this.lazy.load()
      })
    }
  }

  _buildNavigation() {
    if (this._config.navigation.active) {
      this._config = extend(true, this._config, {
        navigation: {
          nextEl: Selector.NAVIGATION_NEXT,
          prevEl: Selector.NAVIGATION_PREV
        }
      })
      append(this._element, createElement('div', Selector.NAVIGATION_PREV.slice(1)))
      append(this._element, createElement('div', Selector.NAVIGATION_NEXT.slice(1)))
    }
  }

  _buildPagination() {
    if (this._config.pagination.active) {
      this._config = extend(true, this._config, {
        pagination: {
          el: Selector.PAGINATION
        }
      })
      append(this._element, createElement('div', Selector.PAGINATION.slice(1)))
    }
  }

  _buildScrollbar() {
    if (this._config.scrollbar.active) {
      this._config = extend(true, this._config, {
        scrollbar: {
          el: Selector.SCROLLBAR
        }
      })
      append(this._element, createElement('div', Selector.SCROLLBAR.slice(1)))
    }
  }

  _buildCurtains() {
    if (this._config.curtains) {
      this.elementPosition = this._element.offsetTop + this._element.offsetHeight - this._config.curtainsEndOffset
      this._slides.forEach(slide => {
        slide.classList.add(ClassName.CURTAINS)
        append(slide, createElement('div', ClassName.CURTAINS_BEFORE))
        prepend(slide, createElement('div', ClassName.CURTAINS_AFTER))
      })
      EventHandler.on(window, Event.SCROLL, () => {
        if (this.inView(this._element)) {
          const scale = this._getViewRange()
          this._slides.forEach(slide => {
            const curtainsBefore = SelectorEngine.findOne(Selector.CURTAINS_BEFORE, slide)
            const curtainsAfter = SelectorEngine.findOne(Selector.CURTAINS_AFTER, slide)
            curtainsBefore.style.transform = `scaleX(${scale})`
            curtainsAfter.style.transform = `scaleX(${scale})`
          })
        }
      })
    }
  }

  _getViewRange() {
    const windowPosition = window.pageYOffset + document.documentElement.clientHeight
    const h = 100
    let diff = this.elementPosition - windowPosition
    if (diff <= 0) {
      diff = 0
    }

    let percent = diff / this._config.curtainsDuration * h
    if (percent <= 0) {
      percent = 0
    }

    let scale = Math.round(percent) / h
    if (scale > 1) {
      scale = 1
    }

    return scale
  }

  _getPropertyRecusive(object, keys) {
    if (keys.length > 1) {
      return this._getPropertyRecusive(object[keys.shift()], keys)
    }

    return object[keys]
  }

  _setPropertyRecusive(object, keys, value) {
    if (keys.length > 1) {
      this._setPropertyRecusive(object[keys.shift()], keys, value)
    } else {
      object[keys] = value
    }
  }

  _toType(obj) {
    return Object.prototype.toString.call(obj).match(/\s([a-z]+)/i)[1].toLowerCase()
  }

  // static
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const carousels = SelectorEngine.find(Selector.DATA_RIDE)
  for (let i = 0, len = carousels.length; i < len; i++) {
    AdvancedCarousel.initializeInterface(carousels[i], Data.get(carousels[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
defineJQueryPlugin(AdvancedCarousel)

export default AdvancedCarousel
